<script setup>
import { ref, onMounted, watch } from 'vue'
import axios from "axios"
import { FilterMatchMode } from 'primevue/api'

const getQueryParam = key => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(key);
}

const token_success = ref()
const contacted = ref("tbd")
const interesting = ref("tbd")
const interesting2 = ref("tbd")
const mailsent = ref("no")
const mailsent_date = ref()
const token = ref()
const workflow = ref()
const workflows = ref([])
const loader = ref(false)
const pw = ref()
const sendMailDialog = ref(false)
const subject = ref()
const msg = ref()
const replyto_email = ref()
const replyto_name = ref()
const unlocked = ref(false)
const activeTabIndex = ref(0)
const filter_workflows = ref({'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })
const schedules = ref([{value: 0, label:"Sofort senden"},{value:1, label:"1 Stunde"}, {value:6, label:"6 Stunden"}, {value:24, label: "1 Tag"},{value: 48, label: "2 Tage"},{value: 72, label: "3 Tage"}])
const schedule = ref(0)

onMounted(() =>{
    token.value = getQueryParam('token')
    if(token.value){
        loader.value = true
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/migrosbank/external', {token: token.value})
        .then(response => {
            if(response.data.status == 'success'){
                token_success.value = true
                workflow.value = response.data.workflow
                console.log("workflow", workflow.value)
                loader.value = false
                let get_contacted = getQueryParam('contacted')
                let get_interesting = getQueryParam('interesting')
                if (get_contacted != null) {
                    contacted.value = getQueryParam('contacted')
                }
                if (get_interesting != null) {
                    interesting.value = getQueryParam('interesting')
                }
                if (workflow.value.mailsent == 'yes'){
                    mailsent.value = 'yes'
                    mailsent_date.value = workflow.value.mailsent_date
                    msg.value = workflow.value.mailsent_msg
                }
            }
            else
            {
                token_success.value = false
                loader.value = false
            }
        })
    }
})

const unlock_leads = () => {
    loader.value = true
    if(pw.value){
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/migrosbank/unlock-leads', {pw: pw.value})
        .then(response => {
            if(response.data.status == 'success'){
                unlocked.value = true
                workflows.value = response.data.workflows
                loader.value = false
                activeTabIndex.value = 1
            }
        })
    }
}

const swiss_date_time = (date) => {
    return new Date(date).toLocaleDateString('de-CH', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

const view_user = (id) => {
    window.open('/ext?token=' + id, "_self")
}

const was_contacted = () => {
    contacted.value = 'yes'
}

const not_contacted = () => {
    contacted.value = 'no'
}

const was_interesting = () => {
    interesting.value = 'yes'
}

const not_interesting = () => {
    interesting.value = 'no'
}

const was_interesting2 = () => {
    interesting2.value = 'yes'
}

const not_interesting2 = () => {
    interesting2.value = 'no'
}

const send_candidate_email = () => {
    //window.open('/ext?token=' + token.value + '&contacted=' + contacted.value + '&interesting=' + interesting.value + '&sent=' + mailsent.value, "_self")
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/migrosbank/send-candidate-email', {token: token.value, subject: subject.value, msg: msg.value, replyto_name: replyto_name.value, replyto_email: replyto_email.value, schedule: schedule.value})
    .then(response => {
        if(response.data.status == 'success'){
            sendMailDialog.value = false
            mailsent.value = 'yes'
            mailsent_date.value = response.data.mailsent_date
        }
    })
}



watch([contacted, interesting, mailsent, interesting2], () => {
    if (mailsent.value == 'no') {
        if (contacted.value == 'no' && interesting.value == 'no' && mailsent.value == 'no' && workflow.value.form.promo == 'kb' || contacted.value == 'tbd' && interesting.value == 'no' && mailsent.value == 'no' && workflow.value.form.promo == 'kb') {
            subject.value = "Deine Bewerbung als " + String(workflow.value.source.title)
            msg.value = "Guten Tag " + workflow.value.form.firstName + "<br><br>Vielen Dank für dein Interesse an der Migros Bank. Es hat uns sehr gefreut, dass du dich für die Position "+String(workflow.value.source.title)+" gemeldet hast. Dass du dir eine berufliche Zukunft bei uns vorstellen kannst, schätzen wir sehr.<br><br>Umso mehr bedauern wir, dir leider absagen zu müssen. Aus unserer Sicht stimmen deine Qualifikationen und dein bisheriger Werdegang zu wenig mit den Anforderungen dieser Position überein. Darum haben wir uns entschieden, dich nicht in die nächste Runde einzuladen.<br><br>Wir wünschen dir alles Gute auf deinem weiteren Weg und viel Erfolg!<br><br>Freundliche Grüsse<br><br>"+String(workflow.value.source.contact_name)+"<br>"+String(workflow.value.source.contact_role)+"<br>"
            replyto_email.value = workflow.value.source.contact_email
            replyto_name.value = workflow.value.source.contact_name
            schedule.value = 24
            sendMailDialog.value = true
        }

        if (contacted.value == 'yes' && interesting.value == 'yes' && interesting2.value == 'no' && mailsent.value == 'no' && workflow.value.form.promo == 'kb' || contacted.value == 'yes' && interesting.value == 'no' && mailsent.value == 'no' && workflow.value.form.promo == 'it') {
            subject.value = "Deine Bewerbung als " + String(workflow.value.source.title)
            msg.value = "Guten Tag " + workflow.value.form.firstName + "<br><br>Vielen Dank für das aufschlussreiche Gespräch und dein Interesse an der Migros Bank. Es hat uns sehr gefreut, dass du dich für die Position "+String(workflow.value.source.title)+" gemeldet hast. Dass du dir eine berufliche Zukunft bei uns vorstellen kannst, schätzen wir sehr.<br><br>Umso mehr bedauern wir, dir leider absagen zu müssen. Aus unserer Sicht stimmen deine Qualifikationen und dein bisheriger Werdegang zu wenig mit den Anforderungen dieser Position überein. Darum haben wir uns entschieden, dich nicht in die nächste Runde einzuladen.<br><br>Wir wünschen dir alles Gute auf deinem weiteren Weg und viel Erfolg!<br><br>Freundliche Grüsse<br><br>"+String(workflow.value.source.contact_name)+"<br>"+String(workflow.value.source.contact_role)+"<br>"
            replyto_email.value = workflow.value.source.contact_email
            replyto_name.value = workflow.value.source.contact_name
            schedule.value = 24
            sendMailDialog.value = true
        }

        if (contacted.value == 'no' && interesting.value == 'yes' && mailsent.value == 'no' && workflow.value.form.promo == 'kb' || contacted.value == 'no' && mailsent.value == 'no' && workflow.value.form.promo == 'it') {
            subject.value = "Deine Bewerbung als " + String(workflow.value.source.title)
            msg.value = "Guten Tag " + workflow.value.form.firstName + "<br><br>Ich habe versucht, dich heute telefonisch zu erreichen, jedoch ohne Erfolg. Der Grund für meinen Anruf ist deine Bewerbung für die vakante Stelle als <b>"+String(workflow.value.source.title)+"</b>.<br><br>Bitte sende mir deinen Lebenslauf per E-Mail, damit ich deine Bewerbung in die nächste Phase mitnehmen kann.<br><br>Ich freue mich auf deine Rückmeldung.<br><br>Freundliche Grüsse<br><br>"+String(workflow.value.source.contact_name)+"<br>"+String(workflow.value.source.contact_role)+"<br>"
            replyto_email.value = workflow.value.source.contact_email
            replyto_name.value = workflow.value.source.contact_name
            schedule.value = 0
            sendMailDialog.value = true
        }

        if (contacted.value == 'yes' && interesting.value == 'yes' && interesting2.value == 'yes' && mailsent.value == 'no' && workflow.value.form.promo == 'kb' || contacted.value == 'yes' && interesting.value == 'yes' && mailsent.value == 'no' && workflow.value.form.promo == 'it') {
            subject.value = "Vielen Dank für das angenehme Gespräch - Nächster Schritt: Lebenslauf"
            msg.value = "Guten Tag " + workflow.value.form.firstName + "<br><br>Ich danke dir herzlich für das angenehme und aufschlussreiche Gespräch, das wir heute führen konnten. Wir würden nun gerne den nächsten Schritt in unserem Auswahlverfahren mit dir gehen.<br><br>Bitte stelle mir dazu deinen Lebenslauf per E-Mail zu. <br><br>Nach Eingang und Prüfung deiner Unterlagen werden wir uns erneut bei dir melden, um die nächsten Schritte zu besprechen.<br><br>Freundliche Grüsse<br><br>"+String(workflow.value.source.contact_name)+"<br>"+String(workflow.value.source.contact_role)+"<br>"
            replyto_email.value = workflow.value.source.contact_email
            replyto_name.value = workflow.value.source.contact_name
            schedule.value = 0
            sendMailDialog.value = true
        }
    }
})

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<style>
.mb_url {
    color: #144B3C;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}
.mb_green {
    background-color: #144B3C;
    color: #FFF;
    border-color: #FFF;
}

.mb_green_border {
    border-color: #144B3C;
}

.mb_pink {
    background-color: #E34055;
    color: #FFF;
    border-color: #E34055;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #144B3C;
    border-color: #144B3C;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: #144B3C;
}
</style>

<template>
    <div class="flex justify-content-center mt-6">
        <div class="surface-card p-4 shadow-2 border-round" style="width: 1000px" >
            <div>
                <ProgressSpinner v-if="loader == true" style="position: fixed; z-index: 999; overflow: show; margin: auto; top: 0; left: 0; bottom: 0; right: 0; width: 50px; height: 50px;" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" aria-label="Loading..." />
                <span v-if="!token_success && !loader" class="text-600 font-medium line-height-3 text-red-500">Dieser Token ist abgelaufen oder nicht mehr gültig!</span>
                <div v-if="token_success && !loader">
                    <div class="text-900 text-3xl font-medium mt-3"><img style="width: 140px;" src="https://migrosbank-customer-jyhmsht7ka-oa.a.run.app/assets/imgs/logo.png" /></div>
                    <div class="mt-3 fadein animation-duration-500">
                        <TabView class="col-12" ref="tabview4" v-model:activeIndex="activeTabIndex">
                            <TabPanel>
                                <template #header>
                                    <i class="pi pi-user mr-2"></i>
                                    <span class="mr-3">Bewerbung</span>
                                </template>
                                <div class="mb-4">
                                    <div class="col-12 formgrid grid rounded border-solid border-1 mb_green_border p-0 shadow-2">
                                        <div class="field col-3 p-2 font-bold mb_green border-solid border-bottom-1 m-0">
                                            Stelle:
                                        </div>
                                        <div class="field col-9 p-2 mb_green_border border-solid border-bottom-1 m-0">
                                            <a class="mb_url" :href="'https://migros-gruppe.jobs' + workflow.source.job_url" target="_blank">{{ workflow.source.title }}</a>
                                        </div>
                                        <div class="field col-3 p-2 font-bold mb_green border-solid border-bottom-1 m-0">
                                            Datum:
                                        </div>
                                        <div class="field col-9 p-2 mb_green_border border-solid border-bottom-1 m-0">
                                            {{ swiss_date_time(workflow.dc) }}
                                        </div>
                                        <div class="field col-3 p-2 font-bold mb_green border-solid border-bottom-1 m-0">
                                            ID:
                                        </div>
                                        <div class="field col-9 p-2 mb_green_border border-solid border-bottom-1 m-0">
                                            {{ workflow.workflow_id }}
                                        </div>
                                        <div class="field col-3 p-2 font-bold mb_green border-solid border-bottom-1 m-0">
                                            Vorname:
                                        </div>
                                        <div class="field col-9 p-2 mb_green_border border-solid border-bottom-1 m-0">
                                            {{ workflow.form.firstName }}
                                        </div>
                                        <div class="field col-3 p-2 font-bold mb_green border-solid border-bottom-1 m-0">
                                            Nachname:
                                        </div>
                                        <div class="field col-9 p-2 mb_green_border border-solid border-bottom-1 m-0">
                                            {{ workflow.form.lastName }}
                                        </div>
                                        <div class="field col-3 p-2 font-bold mb_green border-solid border-bottom-1 m-0">
                                            Social:
                                        </div>
                                        <div class="field col-9 p-2 mb_green_border border-solid border-bottom-1 m-0">
                                            <a class="mb_url" :href="workflow.form.profileUrl" target="_blank">{{ workflow.form.profileUrl }}</a>
                                        </div>
                                        <div class="field col-3 p-2 font-bold mb_green border-solid border-bottom-1 m-0">
                                            Telefonnummer:
                                        </div>
                                        <div class="field col-9 p-2 mb_green_border border-solid border-bottom-1 m-0">
                                            <a class="mb_url" v-if="workflow.form.telephone" :href="'tel:' + workflow.form.telephone">{{ workflow.form.telephone }}</a>
                                        </div>
                                        <div class="field col-3 p-2 font-bold mb_green border-solid border-bottom-1 m-0">
                                            E-Mail:
                                        </div>
                                        <div class="field col-9 p-2 mb_green_border border-solid border-bottom-1 m-0">
                                            <a class="mb_url" v-if="workflow.form.email" :href="'mailto:' + workflow.form.email">{{ workflow.form.email }}</a>
                                        </div>
                                        <div class="field col-3 p-2 font-bold mb_green border-solid border-bottom-1 m-0">
                                            Angestellt:
                                        </div>
                                        <div class="field col-9 p-2 mb_green_border border-solid border-bottom-1 m-0">
                                            <span v-if="workflow.form.employment == 'yes'">Ja</span>
                                            <span v-if="workflow.form.employment == 'no'">Nein</span>
                                        </div>
                                        <div class="field col-3 p-2 font-bold mb_green border-solid border-bottom-1 m-0">
                                            Mitteilung:
                                        </div>
                                        <div class="field col-9 p-2 mb_green_border border-solid border-bottom-1 m-0">
                                            {{ workflow.form.message }}
                                        </div>
                                        <div class="field col-3 p-2 font-bold mb_green border-solid border-bottom-1 m-0 mb_green_border">
                                            Typ:
                                        </div>
                                        <div class="field col-9 p-2 mb_green_border border-solid border-bottom-1 m-0">
                                            <span v-if="workflow.form.promo == 'it'">Migrosbank IT</span>
                                            <span v-if="workflow.form.promo == 'kb'">Migrosbank Kundenberatende</span>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="contacted == 'tbd' && interesting == 'tbd' && mailsent == 'no' && workflow.form.promo == 'it'" class="fadein animation-duration-500">
                                    <div class="text-900 text-3xl font-medium mt-3">Bewerber telefonisch erreicht?</div>
                                    <div class="mt-3">
                                        <Button icon="pi pi-check" @click="was_contacted()" size="large" class="mb_green mr-2 shadow-2" label="Ja" />
                                        <Button icon="pi pi-times" @click="not_contacted()" size="large" class="mb_pink mr-2 shadow-2" label="Nein" />
                                    </div>
                                </div>

                                <div v-if="contacted == 'yes' && interesting == 'tbd' && mailsent == 'no' && workflow.form.promo == 'it'" class="fadein animation-duration-500">
                                    <div class="text-900 text-3xl font-medium mt-3">Ist dieser Bewerber interressant?</div>
                                    <div class="mt-3">
                                        <Button icon="pi pi-check" @click="was_interesting()" size="large" class="mb_green mr-2 shadow-2" label="Ja" />
                                        <Button icon="pi pi-times" @click="not_interesting()" size="large" class="mb_pink mr-2 shadow-2" label="Nein" />
                                    </div>
                                </div>

                                <div v-if="contacted == 'yes' && interesting == 'yes' && mailsent == 'no' && workflow.form.promo == 'it'" class="fadein animation-duration-500">
                                    <div class="text-900 text-3xl font-medium mt-3">CV anfordern</div>
                                    <div class="mt-3">
                                        <Button icon="pi pi-envelope" @click="sendMailDialog = true" size="large" class="mb_green mr-2 shadow-2" label="CV Aufforderung" />
                                    </div>
                                </div>

                                <div v-if="contacted == 'no' && interesting == 'tbd' && mailsent == 'no' && workflow.form.promo == 'it'" class="fadein animation-duration-500">
                                    <div class="text-900 text-3xl font-medium mt-3">CV anfordern</div>
                                    <div class="mt-3">
                                        <Button icon="pi pi-envelope" @click="sendMailDialog = true" size="large" class="mb_green mr-2 shadow-2" label="CV Aufforderung" />
                                    </div>
                                </div>

                                <div v-if="contacted == 'yes' && interesting == 'no' && mailsent == 'no' && workflow.form.promo == 'it' || contacted == 'no' && interesting == 'no' && mailsent == 'no' && workflow.form.promo == 'it'" class="fadein animation-duration-500">
                                    <div class="text-900 text-3xl font-medium mt-3">Bewerber absagen...</div>
                                    <div class="mt-3">
                                        <Button icon="pi pi-envelope" @click="sendMailDialog = true" size="large" class="mb_pink mr-2 shadow-2" label="Absage E-Mail senden" />
                                    </div>
                                </div>

                                <!--- KUNDENBERATENDE -->

                                <div v-if="contacted == 'tbd' && interesting == 'tbd' && mailsent == 'no' && workflow.form.promo == 'kb'" class="fadein animation-duration-500">
                                    <div class="text-900 text-3xl font-medium mt-3">Ist dieser Bewerber interressant?</div>
                                    <div class="mt-3">
                                        <Button icon="pi pi-check" @click="was_interesting()" size="large" class="mb_green mr-2 shadow-2" label="Ja" />
                                        <Button icon="pi pi-times" @click="not_interesting()" size="large" class="mb_pink mr-2 shadow-2" label="Nein" />
                                    </div>
                                </div>

                                <div v-if="contacted == 'tbd' && interesting == 'yes' && mailsent == 'no' && workflow.form.promo == 'kb'" class="fadein animation-duration-500">
                                    <div class="text-900 text-3xl font-medium mt-3">Bewerber telefonisch erreicht?</div>
                                    <div class="mt-3">
                                        <Button icon="pi pi-check" @click="was_contacted()" size="large" class="mb_green mr-2 shadow-2" label="Ja" />
                                        <Button icon="pi pi-times" @click="not_contacted()" size="large" class="mb_pink mr-2 shadow-2" label="Nein" />
                                    </div>
                                </div>

                                <div v-if="contacted == 'yes' && interesting == 'yes' && mailsent == 'no' && interesting2 == 'tbd' && workflow.form.promo == 'kb' || contacted == 'yes' && interesting == 'yes' && interesting2 == 'tbd' && mailsent == 'no' && workflow.form.promo == 'kb'" class="fadein animation-duration-500">
                                    <div class="text-900 text-3xl font-medium mt-3">Bewerber nach Gespräch interressant?</div>
                                    <div class="mt-3">
                                        <Button icon="pi pi-check" @click="was_interesting2()" size="large" class="mb_green mr-2 shadow-2" label="Ja" />
                                        <Button icon="pi pi-times" @click="not_interesting2()" size="large" class="mb_pink mr-2 shadow-2" label="Nein" />
                                    </div>
                                </div>

                                <div v-if="contacted == 'yes' && interesting == 'yes' && interesting2 == 'yes' && mailsent == 'no' && workflow.form.promo == 'kb' || contacted == 'no' && interesting == 'yes' && interesting2 == 'tbd' && mailsent == 'no' && workflow.form.promo == 'kb'" class="fadein animation-duration-500">
                                    <div class="text-900 text-3xl font-medium mt-3">CV anfordern</div>
                                    <div class="mt-3">
                                        <Button icon="pi pi-envelope" @click="sendMailDialog = true" size="large" class="mb_green mr-2 shadow-2" label="CV Aufforderung" />
                                    </div>
                                </div>

                                <div v-if="contacted == 'no' && interesting == 'no' && mailsent == 'no' && workflow.form.promo == 'kb' || contacted == 'tbd' && interesting == 'no' && mailsent == 'no' && workflow.form.promo == 'kb'" class="fadein animation-duration-500">
                                    <div class="text-900 text-3xl font-medium mt-3">Bewerber absagen...</div>
                                    <div class="mt-3">
                                        <Button icon="pi pi-envelope" @click="sendMailDialog = true" size="large" class="mb_pink mr-2 shadow-2" label="Absage E-Mail senden" />
                                    </div>
                                </div>

                                <div v-if="contacted == 'yes' && interesting == 'no' && mailsent == 'no' && workflow.form.promo == 'kb' || contacted == 'yes' && interesting == 'yes' && interesting2 == 'no' && mailsent == 'no' && workflow.form.promo == 'kb'">
                                    <div class="text-900 text-3xl font-medium mt-3">Absage nach Kontakt</div>
                                    <div class="mt-3">
                                        <Button icon="pi pi-check" @click="sendMailDialog = true" size="large" class="mb_pink mr-2 shadow-2" label="Absage E-Mail senden" />
                                    </div>
                                </div>
                                <div class="pl-2 mt-3">
                                    <a class="font-bold cursor-pointer" style="color: #144B3C" v-if="interesting != 'tbd' || contacted != 'tbd'" @click="interesting = 'tbd', contacted = 'tbd', interesting2 = 'tbd'"><i class="pi pi-refresh mr-2" />zurücksetzen</a>
                                </div>

                                <div hidden class="p-5 text-red-500">
                                    {{ contacted }}
                                    {{ interesting }}
                                    {{ interesting2 }}
                                    {{ mailsent }}
                                </div>
                                <div>
                                    <div v-if="mailsent == 'yes'">
                                        <div class="text-900 text-3xl font-medium mt-3">E-Mail wurde an Bewerber gesendet</div>
                                        <div class="mt-3 p-3 bg-green-50 text-green-700 border-green-700 rounded border-2 shadow-2 mr-2">
                                            <div v-html="msg"></div>
                                        </div>
                                        <div v-if="mailsent_date" class="flex align-items-center justify-content-start font-bold mt-3 p-2" style="color: #144B3C"><i class="pi pi-envelope mr-2" />Versandzeitpunkt: {{ swiss_date_time(mailsent_date) }}</div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <template #header>
                                    <i class="pi pi-users mr-2"></i>
                                    <span class="mr-3">Alle Bewerbungen</span>
                                </template>
                                <div v-if="!unlocked">
                                    <div class="col-12 formgrid grid rounded border-solid border-1 mb_green_border p-0">
                                        <div class="flex align-items-center justify-content-center field col-3 p-2 font-bold mb_green border-solid border-bottom-1 m-0 mb_green_border">
                                            Passwort:
                                        </div>
                                        <div class="field col-9 p-2 mb_green_border border-solid border-bottom-1 m-0">
                                            <Password class="w-full" v-model="pw" :inputStyle="{'width': '100%'}" :feedback="false" />
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <Button :disabled="!pw" @click="unlock_leads()" icon="pi pi-unlock" size="large" class="mb_green mr-2 shadow-2" label="Login" />
                                    </div>
                                </div>
                                <div v-if="unlocked">
                                    <DataTable v-model:filters="filter_workflows" :value="workflows" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                                        <Column field="dc" header="Datum">
                                            <template #body="slotProps">
                                                {{ swiss_date_time(slotProps.data.dc) }}
                                            </template>
                                        </Column>
                                        <Column field="source.title" header="Stelle"></Column>
                                        <Column field="form.firstName" header="Vorname"></Column>
                                        <Column field="form.lastName" header="Nachname"></Column>
                                        <Column header="Status">
                                            <template>
                                                <Badge value="Neu" class="mr-2" />
                                            </template>
                                        </Column>
                                        <Column>
                                            <template #body="slotProps">
                                                <Button icon="pi pi-eye" class="p-button-rounded p-button-text" @click="view_user(slotProps.data.workflow_id)" />
                                            </template>
                                        </Column>
                                    </DataTable>
                                </div>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-column mt-10">
        <div class="flex align-items-center justify-content-center mt-3">automated by</div>
        <div class="flex align-items-center justify-content-center"><img src="https://cdn.snpy.ch/static/snappy-logo-g.png" width="150" /></div>
        <div class="flex align-items-center justify-content-center mt-3"><span class="mr-1">Copyright © 2023</span><a href="https://www.impact-solutions.ch" target="_blank">Impact Solutions GmbH</a></div>
    </div>
    <!----------------------------------------------------------->
    <!------------------ Dialog Interesting --------------------->
    <!----------------------------------------------------------->
    <Dialog v-model:visible="sendMailDialog" :style="{width: '700px'}" header="E-Mail an Bewerber" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <InputText id="subject" v-model="subject" class="w-full" placeholder="Betreff" />
                    <label for="subject">Betreff</label>
                </span>
            </div>
            <div class="field col-12 mt-2">
                <Editor editorStyle="width: 100%; min-height: 250px;" v-model="msg">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            <button class="ql-image" v-tooltip.bottom="'Bild hochladen'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="field col-12 mt-4">
                <span class="p-float-label">
                    <Dropdown id="schedule" v-model="schedule" :options="schedules" optionLabel="label" optionValue="value" />
                    <label for="schedule">Versandzeitpunkt</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <div>
                    <Button :disabled="msg ? false : true" label="E-Mail absenden" @click="send_candidate_email()" size="large" class="w-auto mr-2 mb-1 p-button" icon="pi pi-send" />
                    <Button label="Abbrechen" @click="sendMailDialog = false" class="w-auto mr-2 mb-1 p-button-danger" size="large" icon="pi pi-times" />
                </div>
            </div>
        </div>
    </Dialog>
</template>